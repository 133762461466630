h1.ui.header {
  margin-bottom: 20px;
}

h1.ui.header .image:not(.icon) {
  width: auto;
  margin-right: 20px;
}

.ui.menu.large.yellow {
  background-color: #e0aa2f;
  margin-bottom: 20px;
}

#root > .ui.container {
  position: relative;
}

#root > .ui.container > .ui.menu {
  position: absolute;
  top: 0;
  right: 0;
}